// i18next-extract-mark-ns-start contacts-page
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { find } from 'lodash';
import React from 'react';
import { Seo } from '../../components/Seo';
import Layout from '../../layouts/layout';

import * as classes from './aida-pay-success.module.scss';

const AidaPaySuccessPage = props => {
    const { t } = useTranslation();
    return <Layout location={props.location}>
        <div className={clsx('container p-0', classes.PageContainer)}>
            <div className={clsx(classes.AidaPaySuccess)}>
                <div className={classes.Content}>
                    <div className={classes.TitleContainer}>
                        <FontAwesomeIcon icon={[ 'fal', 'check-circle' ]} />
                        <h1 className={classes.Title}>{t('aida-pay-success-page:content.title')}</h1>
                    </div>
                    <Trans i18nKey="aida-pay-success-page:content.description">
                        <p>Your payment through <strong>AIDA Pay</strong> has been completed successfully.</p>
                        <p>We're glad to be part of your journey, ensuring your transactions are smooth and secure.</p>
                        <p>By choosing AIDA Pay, you're not just making a payment; you're embarking on a journey with a
                           platform that values your security and ease.<br />
                           Curious about the magic behind AIDA and AIDA Pay? Dive in and <Link to="/">discover more
                                                                                                      about us</Link>.
                        </p>
                    </Trans>
                </div>
                <StaticImage
                    src="./aida-pay-success-background.jpg" alt={t('aida-pay-success-page:content.title')}
                    placeholder="none"
                    className={clsx(classes.ImageContainer, classes.Desktop)}
                />
                <StaticImage
                    src="./aida-pay-success-background-square.jpg" alt={t('aida-pay-success-page:content.title')}
                    placeholder="none"
                    className={clsx(classes.ImageContainer, classes.Mobile)}
                />
            </div>
        </div>
    </Layout>;
};

AidaPaySuccessPage.propTypes = {};

export default AidaPaySuccessPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
        filter: {ns: {in: ["common", "aida-pay-success-page"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
    const edge = find(data.locales.edges, [ 'node.ns', 'aida-pay-success-page' ]);
    let obj = undefined;
    if (!!edge) {
        obj = JSON.parse(edge.node.data);
    }
    const title = !!obj ? obj.title : 'AIDA Pay payment successful';

    return <Seo title={title} description={!!obj ? obj.description : null} />;
};
